export const formNames = {
  vacancyForm: {
    firstName: 'firstName',
    secondName: 'secondName',
    phone: 'phone',
    email: 'email',
    portfolioLink: 'portfolioLink',
    portfolioFile: 'portfolioFile',
    resumeLink: 'resumeLink',
    resumeFile: 'resumeFile',
    bio: 'bio',
    agree: 'agree',
  },

  contactForm: {
    firstName: 'firstName',
    phone: 'phone',
    email: 'email',
    bio: 'bio',
    agree: 'agree',
  },
}
