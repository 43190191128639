import React from 'react'
import { useFormik } from 'formik'

import { addMessage } from 'src/shared/features/snack'
import { upload } from 'src/shared/upload/upload'

import { formNames, ContactForm, formatters } from 'src/shared/forms'
import { sendAnalyticsEvent } from 'src/shared/features/analytics'

import { breakpoints, useDimensions } from 'src/ui/dimensions'
import { useTheme, styled } from 'src/ui/theme'
import { HBox, Col } from 'src/ui/layout'
import { Input, Button, Checkbox, InputWithUpload } from 'src/ui/molecules'

import { Body } from 'src/ui/typography'

import { validator } from './validator'
import { ADD_LEAD } from './mutations'
import { useMutation } from '@apollo/react-hooks'

type TUploadData = {
  id: string
  name: string
}

const Container = styled.div`
  background: ${({ theme }) => theme.colors.brand.primary};
`

const Fields = styled.div``

const Row = styled(Col)`
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  gap: 32px;

  @media (max-width: ${breakpoints.tablet}px) {
    grid-template-columns: 1fr;
  }
`

const initialValues: ContactForm = {
  firstName: '',
  email: '',
  phone: '',
  bio: '',
  resume: '',
  resumeLink: '',
  agree: false,
}

export const Form: React.FC<{ title: string; closeModal: () => void }> = ({
  title,
  closeModal,
}) => {
  const theme = useTheme()
  const { isMobile } = useDimensions()

  const [isLoading, setIsLoading] = React.useState(false)
  const [touchedForm, setTouchedForm] = React.useState(false)
  const [resume, setResume] = React.useState<File | null>(null)

  const [addLead, { loading }] = useMutation(ADD_LEAD, {
    onCompleted: () => {
      addMessage({
        text: 'Мы уже читаем ваше сообщение ;)',
        id: 'vacancyFormSuccess',
      })
      formik.resetForm()
      sendAnalyticsEvent('TEXT_US_FINISH')
      closeModal()
    },
    onError: () => {
      addMessage({
        text: 'Что-то пошло не так. Попытайтесь позднее',
        id: 'vacancyFormError',
      })
      sendAnalyticsEvent('TEXT_US_FAILURE')
    },
  })

  const formik = useFormik({
    initialValues: initialValues,
    validate: validator,
    onSubmit: async (values) => {
      delete values['agree']

      const sendForm = (resumeId?: string) => {
        addLead({
          variables: {
            data: {
              ...values,
              pageTitle: title,
              type: 'Связаться с нами',
              resume: resumeId ?? '',
            },
          },
        })
      }

      if (!resume) {
        sendForm()
        return
      }

      const filesForUpload = [{ file: resume, prefix: 'resume' }]
      const uploadRequest = upload(filesForUpload)
      if (typeof uploadRequest !== 'boolean') {
        const body: TUploadData[] = await (await uploadRequest).json()
        const resumeId = body
          ? body.find((file) => file.name.indexOf('resume_') !== -1)?.id
          : ''
        sendForm(resumeId)
      }
    },
  })

  React.useEffect(() => {
    if (loading) {
      setIsLoading(true)
    } else {
      setTimeout(() => {
        setIsLoading(false)
      }, 1000)
    }
  }, [loading])

  return (
    <Container>
      <Fields>
        <Row>
          <div>
            <Body color={theme.colors.white.primary} size="secondary">
              Имя
            </Body>
            <HBox height={8} />
            <Input
              name={formNames.contactForm.firstName}
              value={formik.values.firstName}
              placeholder={'Анастасия Иванова'}
              onChange={formik.handleChange}
              error={Boolean(formik.errors.firstName)}
              disabled={isLoading}
              onBlur={(e) => {
                if (e.target.value && !touchedForm) {
                  sendAnalyticsEvent('TEXT_US_START')
                  setTouchedForm(true)
                }
              }}
            />
          </div>

          <div>
            <Body color={theme.colors.white.primary} size="secondary">
              Телефон (по желанию)
            </Body>
            <HBox height={8} />
            <Input
              name={formNames.contactForm.phone}
              value={formik.values.phone}
              placeholder={'8 (901) 451 85 21'}
              onChange={(e) => {
                formik.setFieldValue(
                  formNames.contactForm.phone,
                  formatters.phone(e.target.value || '')
                )
              }}
              disabled={isLoading}
              type="tel"
            />
          </div>

          <div>
            <Body color={theme.colors.white.primary} size="secondary">
              Email
            </Body>
            <HBox height={8} />
            <Input
              name={formNames.contactForm.email}
              value={formik.values.email}
              placeholder={'email@mail.ru'}
              onChange={formik.handleChange}
              error={Boolean(formik.errors.email)}
              disabled={isLoading}
              type="email"
            />
          </div>
        </Row>
        <HBox />

        <div>
          <Body color={theme.colors.white.primary} size="secondary">
            Расскажи о себе
          </Body>
          <HBox height={8} />
          <Input
            name={formNames.contactForm.bio}
            value={formik.values.bio}
            placeholder={'Пару слов о том, почему ты хочешь работать у нас'}
            onChange={formik.handleChange}
            multiline
            disabled={isLoading}
          />
        </div>
        <InputWithUpload
          textName="resumeLink"
          textValue={formik.values.resumeLink || ''}
          fileName="resume"
          onTextChange={(e) => {
            if (e.target.value) {
              formik.setFieldValue('resumeLink', e.target.value)
              setTouchedForm(true)
            }
          }}
          caption="Резюме (опционально)"
          captionColor={theme.colors.white.primary}
          file={resume}
          placeholder={
            isMobile ? 'Ссылка или PDF' : 'Оставь ссылку или прикрепи файл pdf'
          }
          onFileChange={(e) => {
            if (e.target.files && e.target.files.length > 0) {
              setResume(e.target.files[0])
              formik.handleChange(e)
            }
          }}
          onRemoveFile={() => {
            setResume(null)
            formik.setFieldValue('resume', null)
          }}
          accept={['.doc', '.docx', '.pdf', '.txt']}
          disabled={isLoading}
        />
      </Fields>
      {isMobile ? <HBox height={32} /> : <HBox height={16} />}
      <Button
        onClick={() => formik.handleSubmit()}
        variant={'accent'}
        loading={isLoading}
      >
        Отправить ответ
      </Button>
      <HBox height={32} />

      <Checkbox
        value={formik.values.agree}
        onPress={() => {
          formik.setFieldValue(
            formNames.contactForm.agree,
            !formik.values.agree
          )
        }}
        error={Boolean(formik.errors.agree)}
        label={
          <Body color={theme.colors.white.primary} size="secondary">
            Согласен(-а) с{' '}
            <a
              target="_blank"
              style={{ color: '#fff' }}
              href="https://kode.ru/data-policy"
            >
              Политикой обработки персональных данных
            </a>{' '}
            и{' '}
            <a
              target="_blank"
              style={{ color: '#fff' }}
              href="https://kode.ru/data-agreement"
            >
              Согласием на обработку персональных данных
            </a>
          </Body>
        }
      />
    </Container>
  )
}
