import { styled } from 'src/ui/theme'
import { breakpoints } from 'src/ui/dimensions'

export const Block = styled.div`
  flex: 0 0 50%;
  max-width: 50%;

  @media screen and (max-width: ${breakpoints.tablet - 1}px) {
    flex: 0 0 100%;
    max-width: 100%;
  }
`
