import React from 'react'
import { createStore, createEvent } from 'effector'
import { useStore } from 'effector-react'

import { Modal } from 'src/ui/organisms'
import { useDimensions } from 'src/ui/dimensions'

export const openModal = createEvent<string>('openModal')
export const closeModal = createEvent<string>('closeModal')

const modalStore = createStore<{ [name: string]: boolean }>({})
  .on(openModal, (state, name) => ({ ...state, [name]: true }))
  .on(closeModal, (state, name) => ({ ...state, [name]: false }))

export const ModalContainer: React.FC<{ name: string; title: string }> = ({
  name,
  title,
  children,
}) => {
  const visible = useStore(modalStore)
  const { isDesktop, isMobile } = useDimensions()

  return (
    <Modal
      modalIsVisible={visible[name]}
      onClose={() => {
        closeModal(name)
      }}
      title={title}
      isFullScreen={isMobile}
    >
      {children}
    </Modal>
  )
}
