import React, { ReactNode } from 'react'
import { styled } from 'src/ui/theme'

import { PageHeader, PageFooter } from 'src/features'

type TProps = {
  HeaderComponent?: ReactNode;
}

const MobileWrapper = styled.div`
  width: 320px;
  margin: 0 auto;
`
export const MobileLayout: React.FC<TProps> = ({ children, HeaderComponent } ) => (
  <MobileWrapper>
    <PageHeader AdditionalComponent={HeaderComponent}/>
    {children}
    <PageFooter />
  </MobileWrapper>
)
