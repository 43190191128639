import React from 'react'
import styled from 'styled-components'

import { Touchable } from 'src/ui/atoms'
import { VBox } from 'src/ui/layout'
import { Icons } from 'src/ui/icons'
import { Body } from 'src/ui/typography'

const Card = styled.div`
  display: flex;
  align-items: center;
  background-color: ${({ theme }) => theme.colors.gray.bg};
  padding: 16px;
  border-radius: 8px;
  backdrop-filter: blur(16px);
  transition: 0.3s background-color;

  &:hover {
    background-color: #d8d8df;
  }
`

const TextWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-end;
`

const CardTitle = styled.p`
  font-size: 14px;
  line-height: 17px;
  font-weight: 600;
  color: ${({ theme }) => theme.colors.black.bg};
`

const CardSubtitle = styled.p`
  font-size: 12px;
  line-height: 15px;
  color: ${({ theme }) => theme.colors.gray.primary};
`

type Props = {
  link: string
}

export const TortuCard: React.FC<Props> = ({ link }) => {
  return (
    <Touchable link={link} external>
      <Card>
        <TextWrapper>
          <CardTitle>Tortu.io</CardTitle>
          <CardSubtitle>Разговорные интерфейсы</CardSubtitle>
        </TextWrapper>
        <VBox width={16} />
        <Icons.TortuIcon />
      </Card>
    </Touchable>
  )
}
