const phone = (number: string) => {
  if (!number) return number

  if (number[0] === '+') {
    return `+${number.slice(1, number.length).replace(/\D/g, '')}`
  }

  return number.replace(/\D/g, '')
}

export const formatters = { phone }
