import { regExp, VacancyForm } from 'src/shared/forms'

export const validator = (values: VacancyForm) => {
  const errors: VacancyForm = {}

  if (!values.email) {
    errors.email = 'Required'
  } else if (!regExp.email.test(values.email)) {
    errors.email = 'Invalid email address'
  }

  if (!values.firstName || values.firstName?.length < 2) {
    errors.firstName = 'Required'
  }

  if (!values.agree) {
    errors.agree = true
  }

  return errors
}
