import React, { ReactNode } from 'react'
import { MobileLayout } from './mobile'
import { DesktopLayout } from './desktop'

import { useDimensions } from 'src/ui/dimensions'

type Props = {
  withoutNavShadow?: boolean
  HeaderComponent?: ReactNode;
}

export const RootLayout: React.FC<Props> = ({ children, withoutNavShadow, HeaderComponent }) => {
  const { isMobile } = useDimensions()

  React.useEffect(() => {
    const splash = document.getElementsByClassName('splash')

    setTimeout(() => {
      if (splash.length > 0) {
        splash[0].className = 'splash splash-loaded'

        if (typeof location !== 'undefined' && location.hash !== '') {
          const anchor = document.getElementById(location.hash.replace('#', ''))

          if (anchor) {
            anchor.scrollIntoView()
          }
        }
      }
    }, 1500)
  }, [])

  return (
    <div>
      <div>
        {isMobile ? (
          <MobileLayout HeaderComponent={HeaderComponent}>{children}</MobileLayout>
        ) : (
          <DesktopLayout withoutNavShadow={withoutNavShadow} HeaderComponent={HeaderComponent}>
            {children}
          </DesktopLayout>
        )}
      </div>
    </div>
  )
}
