//@ts-ignore
import constants from 'hr/constants'

export const upload = (
  files: {
    file: File
    prefix?: string
  }[]
): boolean | Promise<Response> => {
  if (files.length === 0) {
    return false
  }

  const formData = new FormData()
  files.forEach((fileObj) => {
    formData.append(
      'files',
      fileObj.file,
      fileObj.prefix + '_' + fileObj.file.name
    )
  })

  return fetch(`${constants.static_endpoint}/upload`, {
    method: 'POST',
    body: formData,
    redirect: 'follow',
  })
}
