import React from 'react'
import { Helmet } from 'react-helmet'

import { useDimensions } from 'src/ui/dimensions'

type Props = {
  title?: string
  description?: string
}
export const Meta: React.FC<Props> = ({
  title = 'KODE — Разработка мобильных приложений',
  description = 'KODE — Мы разрабатываем сервисные мобильные приложения для бизнеса, банков, стартапов и жизни',
}) => {
  const image = require('./../../../static/images/social-cover.jpg')

  const { isMobile } = useDimensions()

  return (
    <Helmet>
      <meta charSet="utf-8" />
      <title>{title}</title>
      <meta
        name="keywords"
        content="разработка мобильных приложений, android, ios, web, iot, mobile app development, design, ui/ux"
      />
      <meta name="description" content={description} />
      <meta http-equiv="X-UA-Compatible" content="IE=edge,chrome=1" />

      {isMobile ? (
        <meta name="viewport" content="width=320px, user-scalable=no" />
      ) : (
        <meta
          name="viewport"
          content="width=device-width, initial-scale=1, user-scalable=no"
        />
      )}

      <meta property="og:title" content={title} />
      <meta property="og:description" content={description} />
      <meta property="og:image" content={image} />

      <script type="application/ld+json">{`
        {
            "@context": "https://schema.org",
            "@type": "Organization",
            "url": "https://career.kode.ru",
            "name": "KODE",
            "contactPoint": {
            "@type": "ContactPoint",
            "telephone": "+7-921-107-51-55",
            "contactType": "Customer Support"
            }
        }
    `}</script>
    </Helmet>
  )
}
