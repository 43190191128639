import React from 'react'

import { styled } from 'src/ui/theme'
import { VBox } from 'src/ui/layout'
import { Body } from 'src/ui/typography'

const Container = styled.div`
  display: flex;
`

const Item = styled(Body)<{ active?: boolean }>`
  font-size: 18px;
  a {
    color: ${({ theme, active }) =>
      active ? theme.colors.brand.secondary : theme.colors.black.primary};
    &:hover {
      text-decoration: none;
    }
  }
`

type Props = {
  links: {
    name: string
    url: string
    target?: string
    action?: () => void
    active?: boolean
  }[]
}

export const ExternalMenu: React.FC<Props> = ({ links }) => (
  <Container>
    {links.map((link, index) => (
      <React.Fragment key={index}>
        <VBox width={20} />
        <Item active={link.active}>
          {link.action ? (
            <a onClick={link.action} href="#">
              {link.name}
            </a>
          ) : (
            <a href={link.url} target={link.target}>{link.name}</a>
          )}
        </Item>
      </React.Fragment>
    ))}
  </Container>
)
