import React from 'react'

import { styled, useTheme } from 'src/ui/theme'
import { breakpoints } from 'src/ui/dimensions'
import { Touchable } from 'src/ui/atoms'
import { SocialNetwork } from '../../types'

const Container = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;

  @media screen and (max-width: ${breakpoints.tablet - 1}px) {
    flex-wrap: wrap;
    margin-bottom: -28px;
  }
`

const Item = styled(Touchable)`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 40px;
  height: 40px;
  background-color: ${({ theme }) => theme.colors.gray.bg};
  border-radius: 50%;

  &:not(:last-of-type) {
    margin-right: 10px;

    @media screen and (max-width: ${breakpoints.tablet - 1}px) {
      margin-right: 19px;
    }
  }

  svg {
    transition: all 0.3s;
  }

  &:hover {
    svg[class='tiktok'] {
      stroke: ${({ theme }) => theme.colors.brand.primary};
    }
    svg:not([class='tiktok']) {
      fill: ${({ theme }) => theme.colors.brand.primary};
    }
  }

  @media screen and (max-width: ${breakpoints.tablet - 1}px) {
    width: 32px;
    height: 32px;
    margin-bottom: 28px;
  }
`

type Props = {
  items: SocialNetwork[]
}

export const SocialNetworks = ({ items }: Props) => {
  const theme = useTheme()

  return (
    <Container>
      {items.map(({ link, icon }) => (
        <Item hoverColor={theme.colors.brand.secondary} link={link} external>
          {icon}
        </Item>
      ))}
    </Container>
  )
}
