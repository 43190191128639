import gql from 'graphql-tag'

export const ADD_LEAD = gql`
  mutation AddLead($data: LeadInput) {
    createLead(input: { data: $data }) {
      lead {
        id
      }
    }
  }
`
