import React from 'react'
import { styled } from 'src/ui/theme'
import { breakpoints } from 'src/ui/dimensions'

export const Title = styled.div`
  color: ${({ theme }) => theme.colors.gray.gray80};
  font-size: 15px;
  line-height: 30px;
  font-weight: 700;
  letter-spacing: 2.6px;
  text-transform: uppercase;
  margin-bottom: 40px;

  @media screen and (max-width: ${breakpoints.tabletXS - 1}px) {
    margin-bottom: 20px;
    font-size: 12px;
    line-height: 15px;
  }
`
