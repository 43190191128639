import React from 'react'
import { css } from 'styled-components'
import { styled } from 'src/ui/theme'
import { breakpoints } from 'src/ui/dimensions'
import { Title } from '../../atoms/title'
import { Block } from '../../atoms'

const Container = styled(Block)`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  margin-bottom: 73px;

  @media screen and (max-width: ${breakpoints.desktopXS - 1}px) {
    max-width: 100%;
  }

  @media screen and (max-width: ${breakpoints.tablet - 1}px) {
    margin-bottom: 40px;
  }
`

const ContactItem = styled.div`
  flex: 0 0 50%;
  max-width: 50%;
  padding-right: 20px;

  &:not(:last-of-type) {
    margin-bottom: 47px;
  }

  @media screen and (max-width: ${breakpoints.desktopXS - 1}px) {
    flex: 0 0 auto;
    max-width: 100%;
  }
`

const ContactLink = styled.a<{ isPhoneLink?: boolean }>`
  position: relative;
  display: inline-block;
  font-size: 26px;
  line-height: 32px;
  font-weight: 800;
  color: ${({ theme }) => theme.colors.black.bg};
  transition: color 0.3s ease;

  text-decoration: none;

  margin-bottom: ${({ isPhoneLink }) => (isPhoneLink ? 11 : 8)}px;

  ${({ isPhoneLink }) =>
    !isPhoneLink &&
    css`
      &::after {
        content: '';
        position: relative;
        margin-top: 4px;
        display: block;
        width: 100%;
        height: 1px;
        background-color: ${({ theme }) => theme.colors.black.bg};
        transition: background-color 0.3s ease;
      }
    `};

  @media screen and (min-width: ${breakpoints.tablet}px) {
    &:hover {
      color: ${({ theme }) => theme.colors.brand.primary};

      &::after {
        background-color: ${({ theme }) => theme.colors.brand.primary};
      }
    }
  }

  @media screen and (max-width: ${breakpoints.tablet - 1}px) {
    font-size: 22px;
    line-height: 32px;
  }
`

export const ContactsList = () => {
  return (
    <Container>
      <ContactItem>
        <Title>Трудоустройство</Title>
        <ContactLink target="_blank" href="mailto:recruiting@kode.ru">
          recruiting@kode.ru
        </ContactLink>
      </ContactItem>
    </Container>
  )
}
