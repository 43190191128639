import React from 'react'

import { styled } from 'src/ui/theme'
import { breakpoints } from 'src/ui/dimensions'
import { Body } from 'src/ui/typography'
import { Title } from '../../atoms/title'
import { Block } from '../../atoms'
import { Address } from '../../types'

const Container = styled.div`
  display: flex;
  flex-direction: row;

  @media screen and (max-width: ${breakpoints.tablet - 1}px) {
    margin-bottom: 64px;
  }
`

const Item = styled.div`
  flex: 0 0 50%;
  max-width: 50%;
  display: flex;
  flex-direction: column;

  color: ${({ theme }) => theme.colors.gray.gray80};
  font-size: 18px;
  line-height: 30px;

  @media screen and (max-width: ${breakpoints.tablet - 1}px) {
    flex: 0 0 auto;
    font-size: 14px;
    line-height: 20px;

    &:not(:last-of-type) {
      padding-right: 18px;
    }
  }
`

const ShowOnMap = styled.a`
  color: ${({ theme }) => theme.colors.gray.gray80};
  font-size: 14px;
  line-height: 21px;
  cursor: pointer;
  transition: color 0.3s ease;
  text-decoration: underline;

  @media screen and (max-width: ${breakpoints.tablet - 1}px) {
    font-size: 12px;
  }

  &:hover {
    color: ${({ theme }) => theme.colors.brand.primary};
  }
`

const StyledBody = styled(Body)<{ isCity?: boolean }>`
  font-size: 18px;
  line-height: 30px;
  margin-bottom: 6px;

  color: ${({ theme, isCity }) =>
    isCity ? theme.colors.black.bg : theme.colors.gray.gray80};

  @media screen and (max-width: ${breakpoints.tabletXS - 1}px) {
    font-size: 14px;
    line-height: 20px;
  }
`

const AddressItem = styled.div`
  :not(:last-of-type) {
    ${StyledBody} {
      position: relative;

      &::after {
        content: '·';
        position: relative;
        margin: 0 11px;
      }

      @media screen and (max-width: ${breakpoints.mobile - 1}px) {
        &::after {
          display: none;
        }
      }

      @media screen and (min-width: ${breakpoints.tablet}px) and (max-width: ${breakpoints.desktopXS -
        1}px) {
        &::after {
          display: none;
        }
      }
    }
  }
`

const AddressWrapper = styled.div`
  display: flex;
  flex-direction: row;

  @media screen and (max-width: ${breakpoints.mobile - 1}px) {
    flex-direction: column;

    ${AddressItem} {
      margin-bottom: 16px;
    }
  }

  @media screen and (min-width: ${breakpoints.tablet}px) and (max-width: ${breakpoints.desktopXS -
    1}px) {
    flex-direction: column;

    ${AddressItem} {
      margin-bottom: 16px;
    }
  }
`

type Props = {
  items: Address[]
}

export const AddressList = ({ items }: Props) => {
  return (
    <Block>
      <Title>Адреса офисов</Title>
      <Container>
        {items.map(({ city, addressList }) => (
          <Item>
            <StyledBody isCity>{city}</StyledBody>
            <AddressWrapper>
              {addressList.map(({ address, link }) => (
                <AddressItem>
                  <StyledBody>{address}</StyledBody>
                  <ShowOnMap target="_blank" href={link}>
                    На карте
                  </ShowOnMap>
                </AddressItem>
              ))}
            </AddressWrapper>
          </Item>
        ))}
      </Container>
    </Block>
  )
}
