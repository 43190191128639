import React from 'react'
import { useStaticQuery, graphql } from 'gatsby'

import { Header } from 'src/ui/typography'
import { styled, useTheme } from 'src/ui/theme'
import { breakpoints } from 'src/ui/dimensions'
import { Wrapper } from 'src/ui/layout'
import { Button } from 'src/ui/molecules'
import { Icons } from 'src/ui/icons'
import { ContactsList, SocialNetworks, AddressList } from '../../molecules'
import {
  ModalContainer,
  openModal,
  closeModal,
  modalNames,
} from 'src/shared/features/modal'

import { Form } from '../form'
import { Address, SocialNetwork } from '../../types'
import { Block } from '../../atoms'
import { Title } from '../../atoms/title'

const Container = styled(Wrapper)`
  padding-top: 50px;
  padding-bottom: 143px;

  @media screen and (max-width: ${breakpoints.tablet}px) {
    padding-top: 24px;
    padding-bottom: 24px;
  }
`

const Content = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-between;

  gap: 48px;

  @media (max-width: ${breakpoints.tablet - 1}px) {
    flex-flow: column;
    justify-content: flex-start;
  }
`

const ButtonAction = styled(Block)`
  display: flex;
  justify-content: flex-end;
  align-self: flex-start;

  @media screen and (max-width: ${breakpoints.desktopXS - 1}px) {
    flex: 0 0 auto;
    max-width: 100%;
    flex-grow: 1;
  }

  @media screen and (max-width: ${breakpoints.tablet - 1}px) {
    width: 100%;
    order: 1;
  }

  & > div {
    flex: 0 0 50%;
    max-width: 50%;

    @media screen and (max-width: ${breakpoints.desktopXS - 1}px) {
      flex: 0 0 auto;
      max-width: 100%;
    }

    @media screen and (max-width: ${breakpoints.tablet - 1}px) {
      flex: 0 0 100%;
      max-width: 100%;
      display: flex;
      justify-content: stretch;
      align-items: stretch;

      & > button {
        width: 100%;
      }
    }
  }
`

const LinksList = styled(Block)`
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  margin-top: 40px;

  @media screen and (max-width: ${breakpoints.tablet - 1}px) {
    margin-top: 0;
    margin-bottom: 40px;
  }
`

const StyledButton = styled(Button)`
  min-width: 236px;
  border-radius: 4px;
`

const addresses: Address[] = [
  {
    city: 'Калининград',
    addressList: [
      {
        address: 'Проспект Победы 81',
        link: 'https://go.2gis.com/tuck9',
      },
    ],
  },
  {
    city: 'Санкт-Петербург',
    addressList: [
      {
        address: 'Невский пр., д.104, (Бизнес центр Темпо), эт. 3, оф. 315',
        link: 'https://go.2gis.com/abxvp0',
      },
    ],
  },
]

export const PageFooter = () => {
  const pages = useStaticQuery<GatsbyTypes.PagesItemsQuery>(
    graphql`
      query PagesItems {
        site {
          siteMetadata {
            menuLinks {
              name
              link
            }
          }
        }
      }
    `
  )

  const currentPath =
    typeof location !== 'undefined'
      ? pages.site?.siteMetadata?.menuLinks?.filter(
          (item) => item?.link === location.pathname
        )[0]?.name
      : ''

  const theme = useTheme()

  const iconColor = theme.colors.black.secondary

  const socialNetworks: SocialNetwork[] = [
    {
      link: 'https://vk.com/appkode',
      icon: <Icons.SocialVk color={iconColor} />,
    },
    {
      link: 'https://t.me/appkode',
      icon: <Icons.SocialTg color={iconColor} />,
    },
    {
      link: 'https://www.linkedin.com/company/appkode',
      icon: <Icons.SocialIn color={iconColor} />,
    },
    {
      link: 'https://www.behance.net/appkode',
      icon: <Icons.SocialBe color={iconColor} />,
    },
    {
      link: 'https://dribbble.com/appkode',
      icon: <Icons.SocialDrib color={iconColor} />,
    },
  ]

  return (
    <>
      <Container>
        <Content>
          <div>
            <Title>Мы в социальных сетях</Title>
            <SocialNetworks items={socialNetworks} />
          </div>
          <AddressList items={addresses} />
        </Content>
      </Container>

      <ModalContainer title="Связаться с нами" name={modalNames.contactUs}>
        <Form
          title={
            currentPath
              ? currentPath
              : typeof location !== 'undefined'
              ? location.pathname
              : ''
          }
          closeModal={() => closeModal(modalNames.contactUs)}
        />
      </ModalContainer>
    </>
  )
}
