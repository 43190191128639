import React from 'react'
import { keyframes } from 'styled-components'

import { styled } from 'src/ui/theme'
import { Touchable } from 'src/ui/atoms'
import { Icons } from 'src/ui/icons'

const fadeIn = keyframes`
  0% {
    transform: scale(0.1);
    opacity:0;
  }
  100% {
    transform: scale(1);
    opacity:1;
  }
`

const ControlClose = styled.div`
  display: flex;
  align-items: center;
  animation: 0.1s ${fadeIn} ease-out;
  transform-origin: center center;
`
const ControlMenu = styled.div`
  display: flex;
  align-items: center;
  animation: 0.1s ${fadeIn} ease-out;
  transform-origin: center center;
`

type Props = {
  active: boolean
  onPress: () => void
}
export const MenuButton: React.FC<Props> = ({ active, onPress }) => (
  <Touchable onPress={onPress}>
    {active ? (
      <ControlClose>
        <Icons.Close />
      </ControlClose>
    ) : (
      <ControlMenu>
        <Icons.Menu />
      </ControlMenu>
    )}
  </Touchable>
)
