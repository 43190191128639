import React, { ReactNode } from 'react'
import { styled } from 'src/ui/theme'

import { PageHeader, PageFooter } from 'src/features'

type Props = {
  withoutNavShadow?: boolean
  HeaderComponent?: ReactNode
}

const DesktopWrapper = styled.div`
  width: 100%;
  max-width: 1960px;
  margin: 0 auto;
`
export const DesktopLayout: React.FC<Props> = ({
  children,
  withoutNavShadow,
  HeaderComponent,
}) => (
  <DesktopWrapper>
    <PageHeader withoutNavShadow={withoutNavShadow} AdditionalComponent={HeaderComponent}/>
    {children}
    <PageFooter />
  </DesktopWrapper>
)
