type EventName =
  | 'TEXT_US_START'
  | 'TEXT_US_FINISH'
  | 'TEXT_US_FAILURE'
  | 'RESPOND_VACANCY_START'
  | 'RESPOND_VACANCY_FINISH'
  | 'RESPOND_VACANCY_FAILURE'

export const sendAnalyticsEvent = (name: EventName) => {
  console.log('event', name)
  try {
    if (
      typeof window !== 'undefined' &&
      // @ts-ignore
      typeof window.yaCounter65768221 !== 'undefined'
    ) {
      // @ts-ignore
      yaCounter65768221.reachGoal(name)
    }

    /* FB Pixel: https://www.facebook.com/business/help/964258670337005?id=1205376682832142 */
    if (
      typeof window !== 'undefined' &&
      // @ts-ignore
      typeof window.fbq !== 'undefined'
    ) {
      const fbEvents = {
        TEXT_US_FINISH: 'Lead',
        RESPOND_VACANCY_FINISH: 'SubmitApplication',
      }
      // @ts-ignore
      fbq('track', fbEvents[name])
    }
  } catch (e) {
    console.log('e', e)
  }
}
